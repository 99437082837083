import * as React from "react"
import { graphql } from "gatsby"

import Layout from "./layout2022"
import Seo from "../../../components/seo"
import { StaticImage } from "gatsby-plugin-image"

const JournalIndex = ({ data, location }) => {
  return (
    <Layout location={location}>
      <Seo title="221118 - Small souveniors from Tokyo" />
      <h2 className="font-title-sub text-center">Small souveniors from Tokyo</h2>
      <div className="text-center">
        <StaticImage
          formats={["auto"]}
          src="../../../images/journal/2022/photo_221118_1.jpg"
          width={500}
          quality={95}
        />
      </div>
      <h3 className="font-title-sub text-center">Cat printed drip coffee</h3>
      <p>I happened to find these lovely drip packs at an art supply store in Jinbocho. A cat and book are printed. When it comes to goods of cats’ motifs, it’s difficult for me to leave without anything. </p>
      <div className="text-center">
        <StaticImage
          formats={["auto"]}
          src="../../../images/journal/2022/photo_221118_2.jpg"
          width={500}
          quality={95}
        />
      </div>
      <h3 className="font-title-sub text-center">Mamezara from Kappabashi Street</h3>
      <p>I bought some small dishes (mamezara) in Kappabashi street. I chose different colors and I like the combination of them when putting together. It seems nice to start the collection of dishes, like buying one by one in every traveling.</p>
    </Layout>
  )
}

export default JournalIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
